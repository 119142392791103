.container {
    display: flex;
}

.side_nav {
    margin-left: 40px;
    margin-top: 24px;
}

.tab {
    cursor: pointer;
    margin-bottom: 16px;
}

.active_icon {
    padding: 4px;
    border-radius: 10px;
    background-color: #56d6bf;
    color: white;
}

.tab_active_title {
    margin-left: 16px;
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
}

.deactive_icon {
    padding: 4px;
    border-radius: 10px;
    color: #56d6bf;
    background-color: white;
    box-shadow: 1px 1px 1px 0.5px #edf0ef;
}

.tab_deactive_title {
    margin-left: 16px;
    font-family: var(--font-bold);
    font-size: 14px;
    color: darkgray;
}

.content_container {
    display: block;
    padding: 40px 60px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 100px;
    margin-right: 100px;
    background-color: #f7fafa;
    border-radius: 10px;
}

.active_back {
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 14px;
    width: 160px;
}

.deactive_back {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 14px;
    width: 160px;
}