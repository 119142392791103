body {
  margin: 0px;
}

@font-face {
	font-family: "regular";
	src: url(./assets/fonts/NanumGothic/NanumGothic-Regular.ttf);
}

@font-face {
	font-family: "bold";
	src: url(./assets/fonts/NanumGothic/NanumGothic-Bold.ttf);
}

@font-face {
	font-family: "extraBold";
	src: url(./assets/fonts/NanumGothic/NanumGothic-ExtraBold.ttf);
}

:root {
	--font-regular: "regular";
	--font-bold: "bold";
	--font-extraBold: "extraBold";
}
