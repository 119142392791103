.top_menu {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 60px;
    justify-content: space-between;
}

.back_group {
    display: flex;
    align-items: center;
}

.title {
    margin-left: 16px;
    font-family: var(--font-bold);
    font-size: 20px;
    text-align: left;
}

.top_menu:hover {
    cursor: pointer;
}

.empty_msg {
    font-family: var(--font-bold);
    font-size: 18px;
    color: lightgray;
    margin: 100px 0px;
}

.connect {
    background-color: transparent;
    border-style: none;
    font-family: var(--font-bold);
    font-size: 16px;
    color: #40c2aa;
}

.delete_expert {
    margin-left: 24px;
    background-color: transparent;
    border-style: none;
    font-family: var(--font-bold);
    font-size: 16px;
    color: #ff7878;
}

.connect:hover {
    cursor: pointer;
}

.delete_expert:hover {
    cursor: pointer;
}
