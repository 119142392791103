.container {
    padding: 16px 0px;
}

.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.group {
    display: flex;
}

.user_group {
    display: flex;
    flex-direction: column;
}

.user_group_in {
    margin-bottom: 6px;
    margin-right: 100px;
}

.title {
    font-family: var(--font-bold);
    font-size: 14px;
    color: lightgray;
    margin-bottom: 10px;
    text-align: left;
}

.name {
    font-family: var(--font-bold);
    font-size: 16px;
    color: black;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.name_sub {
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
    text-align: left;
    margin-right: 10px;
}

.email {
    font-family: var(--font-bold);
    font-size: 14px;
    color: darkgray;
    text-align: left;
    margin-bottom: 2px;
}

.info_group {
    display: flex;
}

.info_group_in {
    margin-right: 60px;
    display: flex;
    flex-direction: column;
}