.top_menu {
    display: flex;
    padding: 16px 0px;
    margin-bottom: 32px;
}

.group_option {
    font-family: var(--font-bold);
    font-size: 16px;
    width: 200px;
}

.empty_msg {
    font-family: var(--font-bold);
    font-size: 18px;
    color: lightgray;
    margin: 100px 0px;
}
