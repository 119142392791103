.header_top {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-top: 6px;
    padding-right: 24px;
    padding-bottom: 6px;
    background-color: #e1e7e7;
}

.brandGroup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 60px;
    height: 60px;
}

.brand {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-family: var(--font-bold);
    color: darkgray;
}