.top_menu {
    display: flex;
    margin-top: 24px;
    margin-bottom: 60px;
    justify-content: space-between;
}

.num_expert {
    font-family: var(--font-bold);
    font-size: 20px;
    text-align: left;
}

.add_expert {
    font-family: var(--font-bold);
    font-size: 18px;
    color: #40c2aa;
}

.add_expert:hover {
    cursor: pointer;
}