.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.container {
    padding: 16px 0px;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info_in {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.name {
    font-family: var(--font-bold);
    font-size: 16px;
    text-align: left;
    color: black;
}

.email {
    margin-top: 4px;
    font-family: var(--font-bold);
    font-size: 14px;
    text-align: left;
    color: darkgray;
}

.phone {
    margin-top: 4px;
    font-family: var(--font-bold);
    font-size: 14px;
    text-align: left;
    color: darkgray;
}

.subinfo {
    display: flex;
}

.subinfo_in {
    display: flex;
    justify-content: center;
    align-items: center;
}

.num_group {
    margin-right: 32px;
}

.num_hint {
    font-family: var(--font-bold);
    font-size: 12px;
    color: lightgray;
    margin-bottom: 6px;
}

.num_group {
    display: flex;
}

.num_data {
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
}

.num_group_in {
    margin: 10px 16px;
    display: flex;
    flex-direction: column;
}

.delete {
    width: 60px;
    height: 30px;
    background-color: rgb(255, 128, 128);
    color: white;
    font-family: var(--font-bold);
    font-size: 14px;
    border-radius: 6px;
    border-style: none;
}

.delete:hover {
    cursor: pointer;
}