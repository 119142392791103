.container {
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.container_inside {
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    padding: 40px;
    width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.modal_title {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 16px;
    margin-bottom: 24px;
    color: black;
}

.modal_desc {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 14px;
    margin-bottom: 24px;
    color: lightgray;
    line-height: 150%;
}

.email {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #edf0f0;
    font-family: var(--font-bold);
    font-size: 14px;
    color: black;
    outline-color: #56d6bf;
}

.email::placeholder {
    color: lightgray;
}

.add_btn {
    margin-top: 24px;
    margin-left: 8px;
    margin-right: 8px;
    width: 100px;
    height: 40px;
    background-color: #56d6bf;
    color: white;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.cancel_btn {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 24px;
    width: 100px;
    height: 40px;
    background-color: rgb(234, 233, 233);
    color: darkgray;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.feedback_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.add_btn:hover {
    cursor: pointer;
}

.cancel_btn:hover {
    cursor: pointer;
}

.group_option {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: var(--font-bold);
}