.parent {
    margin: 24px 0;
}

.parent:hover {
    cursor: pointer;
}

.name {
    font-size: 14px;
    color: black;
    font-family: "bold";
    text-align: left;
}

.email {
    font-size: 12px;
    color: rgb(135, 135, 135);
    font-family: "bold";
    text-align: left;
    margin-bottom: 6px;
}

.phoneNumber {
    font-size: 12px;
    color: rgb(135, 135, 135);
    font-family: "bold";
    text-align: left;
}

.divider {
    margin-top: 16px;
    background: rgb(232, 232, 232);
    height: 1px;
    border: 0;
}

.main_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.numStudent {
    font-size: 14px;
    color: rgb(54, 54, 54);
    font-family: "bold";
    text-align: left;
}