.container {
    position: fixed;
    top:0; left: 0; bottom: 0; right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.container_inside {
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.modal_title {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    color: black;
    line-height: 150%;
}

.modal_desc {
    font-family: var(--font-bold);
    text-align: left;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 32px;
    color: rgb(255, 60, 60);
}

.disconnect_btn {
    margin-top: 24px;
    width: 100px;
    height: 40px;
    background-color: #ff8c8c;
    color: white;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
    margin-left: 8px;
    margin-right: 8px;
}

.cancel_btn {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 24px;
    width: 100px;
    height: 40px;
    background-color: rgb(234, 233, 233);
    color: darkgray;
    font-family: var(--font-bold);
    font-size: 16px;
    border-radius: 10px;
    border-style: none;
}

.disconnect_btn:hover {
    cursor: pointer;
}

.cancel_btn:hover {
    cursor: pointer;
}