.devider {
    width: 100%;
    height: 1px;
    background-color: #edf0f0;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    font-family: var(--font-bold);
    font-size: 16px;
    text-align: left;
    color: black;
}

.hint {
    font-family: var(--font-bold);
    font-size: 14px;
    color: lightgray;
}

.name_num {
    font-family: var(--font-bold);
    font-size: 16px;
    color: black;
    margin-top: 6px;
}


.email {
    margin-top: 4px;
    font-family: var(--font-bold);
    font-size: 14px;
    text-align: left;
    color: darkgray;
}

.container {
    padding: 16px 0px;
}

.container:hover {
    cursor: pointer;
}