.parent {
    display: flex;
    padding: 200px 0px;
    justify-content: center;
}

.top {
    display: block;
    background-color: white;
    padding: 50px;
    box-shadow: 1px 1px 1px 0.5px #e1e7e7;
    border-radius: 16px;
    border-style: none;
}

.title_1 {
    margin-bottom: 0px;
    font-family: var(--font-bold);
    font-size: 22px;
    color: black;
}

.title_2 {
    font-family: var(--font-bold);
    font-size: 16px;
    color: lightgray;
    margin-top: 6px;
    margin-bottom: 40px;
}

.input_box {
    width: 240px;
    height: 36px;
    border-style: solid;
    border-radius: 8px;
    border-color: #e1e7e7;
    outline-color: #56d6bf;
    margin-bottom: 16px;
    padding: 0px 10px;
    font-family: var(--font-bold);
    color: black;
}

.input_box::placeholder {
    color: lightgray;
}

.input_group {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.login {
    display: block;
    width: 100%;
    background-color: #56d6bf;
    border-radius: 10px;
    height: 40px;
    color: white;
    border-style: none;
    font-family: var(--font-bold);
    font-size: 16px;
}

.login:hover {
    cursor: pointer;
}