.top_menu {
    margin-top: 24px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: var(--font-bold);
    font-size: 20px;
    text-align: left;
}

.add_btn {
    font-size: 14px;
    font-family: var(--font-bold);
    padding: 10px 16px;
    border-style: none;
    border-radius: 10px;
    color: white;
    background-color: #52dac1;
}

.add_btn:hover {
    cursor: pointer;
}